#bee-plugin-container {
  height: 100%;
}

.plugin-toolbar-container {
  position: absolute;
  display: table;
  table-layout: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #505659;
}

.plugin-toolbar-container .plugin-toolbar {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
}

.plugin-toolbar-container .plugin-toolbar .plugin-toolbar-label {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
}

.plugin-toolbar .btn {
  margin-left: 10px;
}